@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "FontAwesome";
    src: url("fa-solid-900.woff2");
}

:root {
    /* Definiciones de colores del tema */

    --color-primary-blue: #12b6cb;
    --color-secondary-blue: #115c8a;
    --color-default-blue: #bae9ef;
    --color-default-blue-app: #1a2543;

    --color-primary-blue-app: #086cae;

    --color-primary-gray: #575e66;
    --color-secondary-gray: #878787;
    --color-default-gray: #272e31;

    --color-background-gray: #f6f9fc;
    --color-unset: #ffffff;

    --color-error: #ff0000;
    --color-success: #4caf50;

    --font-primary: "Roboto";
    --font-secondary: "Open Sans";
    --font-default: "Roboto Condensed";
    --font-icon-fa: "FontAwesome";

    --transition-menu: 400ms;

    --detalle-info-height: 590px;
    --detalle-info-height-port: 610px;
}

body {
    background-color: var(--color-background-gray);
    margin: 0;
    font-family: var(--font-primary), var(--font-secondary), var(--font-default), -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
    background: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loader-back {
    z-index: 120000 !important;
    color: var(--color-unset);
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.navbar-user-icon {
    font-size: 40px;
    color: var(--color-primary-blue);
}

.rob-con {
    font-family: var(--font-default);
}

.rob-nor {
    font-family: var(--font-primary);
}

.ops-nor {
    font-family: var(--font-secondary);
}

.color-0 {
    color: var(--color-unset);
}

.color-1 {
    color: var(--color-primary-blue);
}

.color-2 {
    color: var(--color-secondary-blue);
}

.color-3 {
    color: var(--color-primary-gray);
}

.color-4 {
    color: var(--color-secondary-gray);
}

.color-5 {
    color: var(--color-error);
}

.color-6 {
    color: var(--color-success);
}

.bg-color-1 {
    background-color: var(--color-primary-blue);
}

.bg-color-2 {
    background-color: var(--color-secondary-blue);
}

.bold {
    font-weight: bold;
}

.lighter {
    font-weight: lighter;
}

.size-10 {
    font-size: 10px;
}

.size-12 {
    font-size: 12px;
}

.size-13 {
    font-size: 13px;
}

.size-15 {
    font-size: 15px;
}

.size-20 {
    font-size: 20px;
}

.size-25 {
    font-size: 25px;
}

.size-30 {
    font-size: 30px;
}

.size-35 {
    font-size: 35px;
}

.size-40 {
    font-size: 40px;
}

.size-45 {
    font-size: 45px;
}

.size-50 {
    font-size: 50px;
}

.mar-right-10 {
    margin-right: 10px;
}

.mar-right-20 {
    margin-right: 20px;
}

.mar-right-30 {
    margin-right: 30px;
}

.mar-top-20 {
    margin-top: 20px;
}

.mar-top-30 {
    margin-top: 30px;
}

.mar-top-50 {
    margin-top: 50px;
}

.navbar-img {
    height: 40px;
    margin: 20px 0px;
}

.flx-grw-1 {
    flex-grow: 1;
}

.pos-rel {
    position: relative;
}

.pad-right-10 {
    padding-right: 50px;
}

.pad-left-20 {
    padding-left: 20px;
}

.bar-main {
    width: 100%;
    height: auto;
    background-color: var(--color-primary-blue);
    color: white;
    display: flex;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 40px;
    margin-bottom: 20px;
}

.bar-content {
    max-width: 1500px;
    margin: auto;
}

@media only screen and (max-width: 1600px) {
    .bar-content {
        width: 90%;
        margin: auto;
    }
}

.acc-content {
    width: 95%;
    margin: auto;
}

.align-self-center {
    align-self: center;
}

.vertical-align-middle {
    vertical-align: middle;
}

.bg-modal-full {
    background-color: var(--color-background-gray);
}

.modal-form {
    background-color: var(--color-unset);
    outline: none;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.modal-small {
    max-width: 550px;
}

.modal-normal {
    max-width: 800px;
}

.modal-large {
    max-width: 1200px;
}

.modal-form-header {
    display: flex;
    /* background-color: var(--color-primary-blue); */
    border-radius: 10px 10px 0px 0px;
    cursor: move;
}

.modal-from-content {
    box-sizing: border-box;
    padding: 20px;
}

.MuiTableSortLabel-icon {
    opacity: 1 !important;
}

.login-page {
    height: 100%;
}

.login-logo-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.login-logo-img {
    max-width: 550px;
}

.full {
    height: 100%;
}

.login-container {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: auto;
}

.login-form-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.login-form-back {
    height: 100%;
    background-color: var(--color-primary-blue);
    display: grid;
    align-content: center;
    color: var(--color-unset);
}

.cancel-logo {
    height: 100%;
    background-color: var(--color-default-blue-app);
    display: grid;
    align-content: center;
    color: var(--color-unset);
}

.login-input {
    width: 80%;
    margin: auto;
    height: 100%;
}

@media only screen and (max-width: 959px) {
    .login-form-back {
        height: 600px;
        padding-top: 20px;
        display: block;
        box-sizing: border-box;
    }

    .login-logo-img {
        max-width: 400px;
    }
}

@media only screen and (max-width: 599px) {
    .login-logo-img {
        max-width: 300px;
    }
}

.login-form-img {
    width: 230px;
}

.bar-main-3 {
    border-bottom: 3px solid var(--color-primary-blue);
    color: var(--color-primary-blue);
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.icon {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-icon-fa);
}

.react-swipeable-view-container {
    transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

.MTableToolbar-searchField-15 {
    width: 500px !important;
    padding-left: 0px;
}

.MTableToolbar-root-10 {
    padding-top: 20px !important;
}

.MuiTableRow-root:hover {
    background: #f5f5f5 !important;
}

.foto-colaborador {
    max-width: 350px;
    border-radius: 20px;
    /* border: 3px solid var(--color-primary-blue);
    border-bottom: 40px solid var(--color-primary-blue); */
    box-shadow: 0px 0px 25px -14px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
}

.directory-cover-container {
    background: linear-gradient(180deg, #6dd5ed 0%, #2193b0 100%);
    color: var(--color-unset);
    box-sizing: border-box;
    padding: 0px 40px;
    text-align: center;
}

.directory-header-description-container {
    align-self: center;
}

.directory-header-description {
    font-family: var(--font-default);
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 40px;
}

.directory-header-logo {
    width: 100%;
    height: 100%;
}

.directory-content {
    width: 80%;
    margin: 40px auto;
}

.directory-pagination-container {
    box-sizing: border-box;
    text-align: center;
    overflow-x: auto;
    padding: 10px 0px;
}

.directory-doctor-name-container {
    border-bottom: 2px solid var(--color-primary-blue);
}

.directory-doctor-name {
    font-family: var(--font-default);
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-primary-blue);
}

.directory-doctor-label {
    font-family: var(--font-secondary);
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    color: var(--color-primary-gray);
}

.directory-doctor-value {
    font-family: var(--font-secondary);
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    color: var(--color-secondary-gray);
}

.directory-doctor-img-container {
    text-align: right;
    margin-right: 20px;
}

.directory-doctor-img {
    width: 200px;
    border-radius: 10px;
    border-bottom: 15px solid var(--color-primary-blue);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
}

.directory-link {
    text-decoration: none;
    color: var(--color-primary-blue);
    transition: var(--transition-menu);
}

.directory-link:hover {
    text-decoration: underline;
    transition: var(--transition-menu);
}

.reporte-producto-header {
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--color-primary-blue);
    color: var(--color-unset);
    font-family: var(--font-primary);
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    border-radius: 5px;
}

.reporte-resumen {
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    border-bottom: 1px solid #eee;
}

.price-footer-container {
    background-color: #3b4650;
    height: auto;
    color: var(--color-unset);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 60px;
}

.price-footer {
    width: 80%;
    margin: auto;
    text-align: right;
}

.price-footer-contact {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: white;
}

#root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.bg-header {
    background: linear-gradient(to right, #4099ff, #6E7FF3);
}

.cursor-pointer {
    cursor: pointer;
}

.bg-card {
    background-color: steelblue !important;
    color: white !important;
}

.text-gray-900 {
    color: white !important;
}

.w-10 {
    color: white !important;
}

.btn-danger {
    color: #fff !important;
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
}

.MuiIconButton-colorPrimary {
    color: rgb(0 0 0 / 87%) !important;
}

.swal-button--danger {
    background-color: #ffc107;
}

.swal-button--danger:not([disabled]):hover {
    background-color: #b89019;
}

.swal-button--cancel {
    color: #ffffff;
    background-color: var(--color-error);
}

.swal-button--cancel:not([disabled]):hover {
    color: #ffffff;
    background-color: #8a1111;
}

.MuiButton-containedPrimary {
    color: #FFFFFF;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.btn-confirm {
    background-color: #4099ff;
    border-color: #4099ff;
    color: #fff;
}

.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
    background-color: #acbad4 !important;
    border-color: #acbad4 Im !important;
    color: #fff !important;
    cursor: pointer !important;
}

.css-1fajery-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: #acbad4 !important;
    border-color: #acbad4 Im !important;
    color: #fff !important;
}

.MuiTableRow-hover {
    cursor: pointer !important;
}

.btn.btn-primary {
    color: #fff !important;
    background-color: #00bcd4 !important;
    border-color: #00bcd4 !important;
}

.dashed {
    width: 560px !important;
}

.dashed530 {
    width: 530px !important;
}

.align-div-center {
    height: 240px !important;
    width: 560px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}